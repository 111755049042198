import * as React from "react";
import RegisterViewBalance from "../views/RegisterViewBalance";

const RegisterPage = (props: any) => {
  return (
    <>
      <RegisterViewBalance
        pathName={props.location.pathname}
      ></RegisterViewBalance>
    </>
  );
};

export default RegisterPage;
