exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-base-subscription-tsx": () => import("./../../../src/pages/base_subscription.tsx" /* webpackChunkName: "component---src-pages-base-subscription-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-metabolic-tsx": () => import("./../../../src/pages/metabolic.tsx" /* webpackChunkName: "component---src-pages-metabolic-tsx" */),
  "component---src-pages-policy-tsx": () => import("./../../../src/pages/policy.tsx" /* webpackChunkName: "component---src-pages-policy-tsx" */),
  "component---src-pages-register-balance-man-woman-tsx": () => import("./../../../src/pages/register_balance_man_woman.tsx" /* webpackChunkName: "component---src-pages-register-balance-man-woman-tsx" */),
  "component---src-pages-register-biological-age-tsx": () => import("./../../../src/pages/register_biological_age.tsx" /* webpackChunkName: "component---src-pages-register-biological-age-tsx" */),
  "component---src-pages-register-elitista-ms-tsx": () => import("./../../../src/pages/register_elitista_ms.tsx" /* webpackChunkName: "component---src-pages-register-elitista-ms-tsx" */),
  "component---src-pages-register-elitista-tsx": () => import("./../../../src/pages/register_elitista.tsx" /* webpackChunkName: "component---src-pages-register-elitista-tsx" */),
  "component---src-pages-register-food-pharmacy-tsx": () => import("./../../../src/pages/register_food_pharmacy.tsx" /* webpackChunkName: "component---src-pages-register-food-pharmacy-tsx" */),
  "component---src-pages-register-healthqlub-tsx": () => import("./../../../src/pages/register_healthqlub.tsx" /* webpackChunkName: "component---src-pages-register-healthqlub-tsx" */),
  "component---src-pages-register-sigrid-tsx": () => import("./../../../src/pages/register_sigrid.tsx" /* webpackChunkName: "component---src-pages-register-sigrid-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-remove-data-tsx": () => import("./../../../src/pages/remove_data.tsx" /* webpackChunkName: "component---src-pages-remove-data-tsx" */),
  "component---src-pages-saluto-policies-tsx": () => import("./../../../src/pages/saluto_policies.tsx" /* webpackChunkName: "component---src-pages-saluto-policies-tsx" */),
  "component---src-pages-saluto-user-policies-tsx": () => import("./../../../src/pages/saluto_user_policies.tsx" /* webpackChunkName: "component---src-pages-saluto-user-policies-tsx" */),
  "component---src-pages-user-policy-tsx": () => import("./../../../src/pages/user_policy.tsx" /* webpackChunkName: "component---src-pages-user-policy-tsx" */)
}

