import React, { useRef, useState } from "react";
import useTranslation from "../hooks/useTranslation";
import { init } from "@emailjs/browser";
import emailjs from "@emailjs/browser";
import { AirPlane, Heart } from "../commons/Icons/Icons";
import Logo2 from "../assets/images/Logo2.png";
import foodhealthLogo from "../assets/images/fpLogo.png";

interface RegisterProps {
  pathName: string;
}

interface RegisterInput {
  firstName?: string;
  lastName?: string;
  companyOrOrg?: string;
  email?: string;
  pnr?: string;
  donLocation?: string;
}

interface ConfirmViewProps {
  text: string;
  buttonText: string;
}

function ConfirmView(props: ConfirmViewProps) {
  return (
    <div className="m-4 flex flex-col gap-y-4 h-3/4">
      <div className="font-bold">{props.text}</div>
      <div className="w-full ">
        <AirPlane></AirPlane>
      </div>

      <button
        style={{ color: "whitesmoke" }}
        className="bg-slate-800 p-5 w-full  rounded-md"
        onClick={() => {
          window.location.href = "https://youtu.be/XJU8VY1H0PA";
          return false;
        }}
      >
        {props.buttonText}
      </button>
    </div>
  );
}

function RegisterFoodPharamacy(props: RegisterProps) {
  const { t } = useTranslation(props.pathName);
  const [status, setStatus] = useState<"notSent" | "sent" | "loading">(
    "notSent"
  );

  const [formValues, setFormValues] = useState<RegisterInput>({});

  init("qsoSQtlM6Axqe-U2Z");
  const sendEmail = async (event: any) => {
    if (!form.current) {
      console.error("Something must have gone wrong...");
      return false;
    }

    const validateInput = (input: RegisterInput) => {
      const errors: string[] = [];

      if (!input.firstName === undefined) errors.push("Provide a name!");
      if (!input.lastName === undefined) errors.push("Provide an email!");
      if (!input.email === undefined) errors.push("Provide an email!");
      if (!input.pnr === undefined) errors.push("Provide a message!");

      if (errors.length === 0) return null;
      return errors;
    };

    const values = {
      firstName: form.current.firstName.value,
      lastName: form.current.lastName.value,
      email: form.current.email.value,
      pnr: form.current.pnr.value,
      donLocation: form.current.donLocation.value,
      companyOrOrg: "FOOD PHARMACY",
    };

    const errors = validateInput(values);

    if (errors !== null) {
      alert(errors.join("\n"));
      return false;
    }

    try {
      setStatus("loading");
      emailjs.send("service_k88bifl", "template_k3mbjcj", values);
      setTimeout(() => {
        setStatus("sent");
      }, 500);

      // alert(
    } catch (error) {
      console.error(error);
    }
    return false;
  };

  const form = useRef<HTMLFormElement | null>(null);

  return (
    <div className="pb-0 sm:pb-20 ">
      <div className="flex mb-20 sm:w-1/3 w-full sm:m-auto flex-col">
        <div className="flex flex-row items-end pt-5 ">
          <div className="font-bold w-3/5 m-5 pr-5">
            <img src={foodhealthLogo} alt="Food Health Logo" />
          </div>
          <div className="font-bold w-3/5 m-auto">
            <img src={Logo2} alt="Logo 2" />
          </div>
        </div>

        {status == "notSent" && (
          <form className="m-4" ref={form}>
            <div className="font-bold">
              {t(
                "Fill in the details below to register as a SALUTO user. Once you have been verified as a user, you will receive a welcome email and be able to log in to the mobile application."
              )}
            </div>
            <div className="mt-4">
              {t(
                "The personal identity number is required for our doctors to be able to issue a referral for blood sampling, as well as for secure login through BankID."
              )}
            </div>
            <div className="mb-4 mt-10">
              <label className=" text-gray-700 text-sm font-bold mb-2">
                {t("First name")}
              </label>
              <input
                onChange={(event) =>
                  setFormValues({ firstName: event.target.value })
                }
                className="shadow appearance-none border bg-transparent rounded w-full py-2 px-3 text-gray-700 leading-6 focus:outline-none focus:shadow-outline "
                id="firstName"
                type="text"
                placeholder={t("First name")}
              />
            </div>
            <div className="mb-4">
              <label className=" text-gray-700 text-sm font-bold mb-2">
                {t("Last name")}
              </label>
              <input
                onChange={(event) =>
                  setFormValues({ lastName: event.target.value })
                }
                className="shadow appearance-none border bg-transparent rounded w-full py-2 px-3 text-gray-700 leading-6 focus:outline-none focus:shadow-outline "
                id="lastName"
                type="text"
                placeholder={t("Last name")}
              />
            </div>
            <div className="mb-4">
              <label className=" text-gray-700 text-sm font-bold mb-2">
                {t("Email")}
              </label>
              <input
                onChange={(event) =>
                  setFormValues({ email: event.target.value })
                }
                className="shadow appearance-none border bg-transparent rounded w-full py-2 px-3 text-gray-700 leading-6 focus:outline-none focus:shadow-outline "
                id="email"
                type="text"
                placeholder={t("Email")}
              />
            </div>
            <div className="mb-4">
              <label className=" text-gray-700 text-sm font-bold mb-2">
                {t("Desired location for blood sampling")}
              </label>
              <input
                onChange={(event) =>
                  setFormValues({ donLocation: event.target.value })
                }
                className="shadow appearance-none border bg-transparent rounded w-full py-2 px-3 text-gray-700 leading-6 focus:outline-none focus:shadow-outline "
                id="donLocation"
                type="text"
                placeholder={t("Desired location for blood sampling")}
              />
            </div>
            <div className="mb-4">
              <label className=" text-gray-700 text-sm font-bold mb-2">
                {t("Social security number")}
              </label>
              <input
                onChange={(event) => setFormValues({ pnr: event.target.value })}
                className="shadow appearance-none border bg-transparent rounded w-full py-2 px-3 text-gray-700 leading-6 focus:outline-none focus:shadow-outline "
                id="pnr"
                type="text"
                placeholder={t("Social security number")}
              />
            </div>
            <button
              onClick={() => sendEmail(formValues)}
              id="tihs is the id"
              style={{ color: "whitesmoke" }}
              className="bg-slate-800 p-5 w-full flex items-stretch  rounded-md justify-center"
            >
              {t("Send")}
            </button>
          </form>
        )}

        {status == "loading" && (
          <div className="w-1/3 h-1/3 m-auto">
            <Heart></Heart>
          </div>
        )}
        {status == "sent" && (
          <ConfirmView
            buttonText={t("Watch video")}
            text={t(
              "Tack för din registrering! Du kommer att få ett e-mail med en beskrivning hur du kommer igång så snart vi aktiverat dig som användare."
            )}
          ></ConfirmView>
        )}
      </div>
    </div>
  );
}

export default RegisterFoodPharamacy;
