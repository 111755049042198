import React from "react";
import RegisterHealthQlub from "../views/RegisterViewHealthqlub";

const HealthQlubPage = (props: any) => {
  return (
    <>
      <RegisterHealthQlub
        pathName={props.location.pathname}
      ></RegisterHealthQlub>
    </>
  );
};

export default HealthQlubPage;
