import * as React from "react";
import RegisterBiologicalAge from "../views/RegisterViewBiologicalAge";

const RegisterPage = (props: any) => {
  return (
    <>
      <RegisterBiologicalAge
        pathName={props.location.pathname}
      ></RegisterBiologicalAge>
    </>
  );
};

export default RegisterPage;
