import React from "react";
import RegisterFoodPharamacy from "../views/RegisterViewFoodPharmacy";

const FoodPharmacyPage = (props: any) => {
  return (
    <>
      <RegisterFoodPharamacy
        pathName={props.location.pathname}
      ></RegisterFoodPharamacy>
    </>
  );
};

export default FoodPharmacyPage;
